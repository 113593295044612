import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { CustomTabsComponent } from '../custom-tabs/custom-tabs.component';
import { PopupService } from '../../services/popup.service';
import { SearchService } from '../../services/search.service';
import { CustomStepperComponent } from '../custom-stepper/custom-stepper.component';
import { SharedModule } from '../../modules/shared/shared.module';
import { debounceTime, distinctUntilChanged, filter, Subject, Subscription, tap } from 'rxjs';
import { AuthService } from '../../services';
import { UserStateEnum } from '../../microservice-clients/user';
import { SearchLevel, SearchYear } from '../../models/search-school.model';

enum LessonTypeEnum {
  AtHOme = 'AtHome',
  Online = 'Online'
}
@Component({
  selector: 'app-search-dialog',
  standalone: true,
  imports: [CommonModule, CustomTabsComponent, CustomStepperComponent, SharedModule],
  templateUrl: './search-dialog.component.html',
  styleUrl: './search-dialog.component.scss'
})
export class SearchDialogComponent {

  constructor(
    public authService: AuthService,
    public searchService: SearchService,
    private popupService: PopupService,
  ) {
    this.searchService.clearAll();
  }

  @ViewChild('tabs') tabs: CustomTabsComponent;
  @ViewChild('bottomContainer') bottomContainer: ElementRef;

  postCodeInputChange = new Subject()
  postCodeInputChangeSubscription: Subscription;
  showLevels: boolean;
  showYears: boolean;
  isNextDisabled: boolean = true;
  selectedSchoolType;
  lessonType: LessonTypeEnum

  get LessonTypeEnum(): typeof LessonTypeEnum {
    return LessonTypeEnum;
  }

  get UserStateEnum(): typeof UserStateEnum {
    return UserStateEnum;
  }

  ngAfterViewInit() {
    this.applyPostCodeFilter();
  }

  onSchoolTypeSelect(selectedSchoolType) {
    this.searchService.selectedSchoolTypeId = selectedSchoolType.id;
    this.selectedSchoolType = selectedSchoolType;
    if (selectedSchoolType.levels.length > 1) {
      this.showLevels = true;
      this.isNextDisabled = true;
    } else {
      this.searchService.setLevel(selectedSchoolType.levels[0]);
      this.showLevels = false;
      this.showYears = false;
      this.isNextDisabled = false;
    }
  }

  onLevelSelect(selectedLevel: SearchLevel) {
    this.searchService.onLevelSelect(selectedLevel);
    this.showYears = true;
    this.isNextDisabled = true
    this.scrollToBottom()
  }

  onYearSelect(selectedYear: SearchYear) {
    this.searchService.setAvailableCoursesByYearNgSelect(selectedYear);
    this.checkIfNextDisabled()
  }

  scrollToBottom() {
    // this SetTimeout needed for the animation to finish, there is no workaround 
    setTimeout(() => {
      this.bottomContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  }

  applyPostCodeFilter() {
    this.postCodeInputChangeSubscription = this.postCodeInputChange.pipe(
      tap((postCode: string) => {
        this.searchService.searchFilterQueryParams.postCode = postCode;
      }),
      debounceTime(1000),
      distinctUntilChanged(),
      filter((postCode: string) => postCode.length >= 5)
    ).subscribe(() => {
      this.searchService.validatePostCodeAndSetCoordinates().then(resp => {
        this.checkIfNextDisabled()
      })
    });
  }

  checkIfNextDisabled() {
    if (this.tabs.currentTab() === 2 && !this.searchService.selectedCourseIds?.length) {
      this.isNextDisabled = true
    }
    else if (this.tabs.currentTab() === 3) {
      if (this.lessonType === LessonTypeEnum.Online) {
        this.isNextDisabled = false
        return
      }
      if (this.lessonType === LessonTypeEnum.AtHOme && !this.searchService.showPostCodeError && this.searchService.postCode?.length > 5) {
        this.isNextDisabled = false
        return
      }
      this.isNextDisabled = true
    }
    else if (this.searchService.selectedYear) {
      this.isNextDisabled = false
    }
  }

  closeDialog() {
    this.popupService.onClose.emit();
  }

  prevStep() {
    this.tabs.prevTab();
    this.checkIfNextDisabled()
  }

  nextStep() {
    if (this.tabs.currentTab() === 3) {
      this.searchService.setQueryParamObjBasedOnSelection();
      this.searchService.search();
      this.closeDialog();
      this.searchService.navigateToSearch(false);
    } else {
      this.tabs.nextTab();
      this.checkIfNextDisabled()
    }
  }

  selectAtHomeOrOnline(lessonType: LessonTypeEnum) {
    this.lessonType = lessonType;
    this.searchService.isOnline = lessonType === LessonTypeEnum.Online ? true : false;
    this.checkIfNextDisabled()
  }
}
