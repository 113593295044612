import { AdditionalInformationComponent } from '../additional-information/additional-information.component';
import { CustomTabsComponent } from '../../../../standalone-components/custom-tabs/custom-tabs.component';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-additional-information-dialog',
  standalone: true,
  imports: [CommonModule, CustomTabsComponent, AdditionalInformationComponent],
  templateUrl: './additional-information-dialog.component.html',
  styleUrls: ['./additional-information-dialog.component.scss']
})
export class AdditionalInformationDialogComponent {
  @ViewChild('additionalInformation') additionalInformation: AdditionalInformationComponent
  @Output('onProfileCompleted') onProfileCompleted = new EventEmitter()

  constructor(
    private dialogRef: MatDialogRef<AdditionalInformationDialogComponent>
  ) { }

  close() {
    this.dialogRef.close()
  }

  profileCompleted() {
    this.onProfileCompleted.emit()
    this.close()
  }
}