import { Display } from '../../constants';
import { SharedModule } from '../../modules/shared/shared.module';
import { UtilityService } from '../../services/utility.service';
import { CustomStepperComponent } from '../custom-stepper/custom-stepper.component';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, signal, ViewChild, computed, Signal, WritableSignal } from '@angular/core';

@Component({
    selector: 'app-custom-tabs',
    standalone: true,
    imports: [CommonModule, SharedModule, CustomStepperComponent],
    templateUrl: './custom-tabs.component.html',
    styleUrls: ['./custom-tabs.component.scss'],
})
export class CustomTabsComponent implements OnInit {
    constructor(
        private utilityService: UtilityService
    ) { }

    @ViewChild('content') content: ElementRef;
    @ViewChild('stepper') stepper: CustomStepperComponent;

    @Input('isTabInCenter') isTabInCenter: boolean = true;
    @Input('showStepper') showStepper: boolean = true;
    @Input('activeTabIndex') activeTabIndex = signal(0);
    @Input('currentTab') currentTab: Signal<number> = computed(() => {
        return this.activeTabIndex() + 1
    });

    tabs: any[] = [];
    activeTab;
    totalTabs: WritableSignal<number> = signal(0);
    tabChangeDuration = 300;
    private resizeObserver: ResizeObserver;

    ngOnInit(): void {
        if (this.utilityService.isBrowser) {
            this.resizeObserver = new ResizeObserver(this.onResize.bind(this));
        }
    }

    private onResize(entries: ResizeObserverEntry[]) {
        const element = entries[0].target;
        // @ts-ignore
        const newHeight = (this.stepper ? this.stepper.stepperContainer?.nativeElement.offsetHeight : 0) + element.offsetHeight;
        this.content.nativeElement.style.height = newHeight + 'px';
    }

    ngAfterViewInit() {
        if (this.utilityService.isBrowser) {
            this.content.nativeElement.childNodes.forEach((tab) => {
                if (tab.nodeName !== '#comment') {
                    this.tabs.push(tab);
                }
            });
            this.totalTabs.update(() => this.tabs.length);
            this.tabs.forEach((tab) => {
                tab.style.display = Display.none;
            });
            this.activeTab = this.tabs[this.activeTabIndex()];
            this.activeTab.style.display = Display.block;
            this.resizeObserver.observe(this.activeTab);
        }
    }

    goToTab(index: number) {
        while (this.currentTab() !== index) {
            if (this.currentTab() < index) {
                this.nextTab();
            } else {
                this.prevTab();
            }
        }
    }

    delayedNextTab(miliSeconds: number) {
        setTimeout(() => {
            this.nextTab();
        }, miliSeconds);
    }

    nextTab() {
        this.updateTabIndex(1)
    }

    prevTab() {
        this.updateTabIndex(-1)
    }

    private updateTabIndex(directionIndicator: number) {
        this.activeTabIndex.update((currValue) => {
            if ((currValue + directionIndicator) < 0 || (currValue + directionIndicator) === this.tabs.length) {
                return currValue
            }
            this.animateTabChange(directionIndicator)
            return (currValue + directionIndicator)
        });
    }

    private animateTabChange(directionIndicator: number) {
        this.activeTab.style.display = Display.none;
        this.resizeObserver.unobserve(this.activeTab);
        this.activeTab = this.tabs[this.activeTabIndex() + directionIndicator];
        this.activeTab.style.display = Display.block;
        this.resizeObserver.observe(this.activeTab);

        this.activeTab.animate(
            [
                { transform: `translate3d(${directionIndicator ? '100%' : '-100%'}, 0, 0)`, offset: 0 },
            ],
            {
                duration: this.tabChangeDuration,
            }
        )
    }
}