<div class="tutor-education-container">
    <form [formGroup]="educationForm">
        <div class="education-select-container">
            <div translate class="small-title">
                texts.TutorRegistrationSection_highest_education_label
            </div>
            <ng-select (change)="suggestTutorLevel()" formControlName="highestEducation" (click)="scrollToBottom()"
                class="highest-education-select education-select" [searchable]="false" dropdownPosition="bottom"
                [clearable]="false"
                placeholder="{{ 'texts.TutorRegistrationSection_highest_education_label' | translate }}">
                <ng-option *ngFor="let highestEducation of highestEducationList" [value]="highestEducation.value"
                    [ngClass]="{'option-active': highestEducation.value === highestEducationList.value}">
                    <span translate>{{ highestEducation.text }}</span>
                </ng-option>
            </ng-select>
        </div>
        <div class="education-select-container">
            <div translate class="small-title">
                texts.TutorRegistrationSection_current_education_label
            </div>
            <ng-select (change)="suggestTutorLevel()" formControlName="currentEducation" (click)="scrollToBottom()"
                class="current-education-select education-select" [searchable]="false" dropdownPosition="bottom"
                [clearable]="false"
                placeholder="{{ 'texts.TutorRegistrationSection_current_education_label' | translate }}">
                <ng-option *ngFor="let currentEducation of currentEducationList" [value]="currentEducation.value"
                    [ngClass]="{'option-active': currentEducation.value === currentEducationList.value}">
                    <span translate>{{ currentEducation.text }}</span>
                </ng-option>
            </ng-select>
        </div>
        <div *ngIf="suggestedTutorLevel" class="suggested-tutor-level-container">
            <app-svg-icon name="info-circle-blue"></app-svg-icon>
            <div class="suggested-tutor-level-text">
                {{ suggestedTutorLevel | translate }}
            </div>
        </div>
        <button *ngIf="!isLandingPage" (click)="confirm()" class="button confirm-button"
            [ngClass]="{'button-enabled':  educationForm.valid}" translate>texts.Misc_next</button>
    </form>
    <div #bottomContainer></div>
</div>