<div [formGroup]="parentForm" [class]="'input-container' + ' ' + geoService.language">
    <div class="input-and-title" *ngFor="let field of geoService.formFields;"
        [class]="'address-input-field ' + field.fieldClass">
        <div class="input-title">
            {{field.placeholder | translate}}
        </div>
        <input *ngIf="!isReadOnly(field)" class="address-input-field" type="text" [name]="field.name"
            [formControlName]="field.name" [placeholder]="field.placeholder | translate" required=""
            (keyup)="onKeyUp.next($event)" (paste)="updateFields()">
        <input *ngIf="isReadOnly(field)" class="address-input-field read-only" type="text" [name]="field.name"
            [formControlName]="field.name" [placeholder]="field.placeholder | translate" required="">
        <div class="inline-error"
            [ngClass]="{'show-error': (parentForm.get(field.name).status === 'INVALID') && showError && !isReadOnly(field)}"
            translate>
            texts.Misc_field_message_required
        </div>
        <div class="inline-error" [ngClass]="{'show-error': geoService.showInvalidMessage}">
            {{'texts.Modal_register_student_cant_find_address' | translate}}
        </div>
    </div>
    <div class="inline-error" [ngClass]="{'show-error': geoService.showGroupMessage}">
        {{'texts.InputGroupLocationComponent_error' | translate}}
    </div>
    <div *ngIf="showConfirmButton" class="button confirm-button" (click)="confirm()" translate>texts.Misc_next_button
    </div>
</div>