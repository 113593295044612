import { SelectRegisterTypeComponent } from './../select-register-type/select-register-type.component';
import { PhoneInputComponent } from './../phone-input/phone-input.component';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/modules/material/material.module';
import { SharedModule } from 'app/modules/shared/shared.module';
import { Validators } from '@angular/forms';
import { InputRequiredErrorComponent } from 'app/standalone-components/input-required-error/input-required-error.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoginOrRegisterFrame } from '../login-or-register-frame/login-or-register-frame.component';
import { RegisterUserRequest } from 'app/microservice-clients/user';
import { MessageType } from 'app/constants';
import { AdditionalInformationComponent } from 'app/standalone-components/common-dialogs/additional-information/additional-information/additional-information.component';
import { PersonalDataComponent } from '../personal-data/personal-data.component';
import { RegisterBaseComponent } from '../base-classes/register-base-class.component';
import { CustomTabsComponent } from 'app/standalone-components/custom-tabs/custom-tabs.component';

@Component({
  selector: 'app-student-register-dialog',
  templateUrl: './student-register-dialog.component.html',
  styleUrls: ['./student-register-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, RecaptchaModule, AdditionalInformationComponent, MaterialModule, FormsModule, CustomTabsComponent, LoginOrRegisterFrame, InputRequiredErrorComponent, PersonalDataComponent, PhoneInputComponent, SelectRegisterTypeComponent],
})

export class StudentRegisterDialogComponent extends RegisterBaseComponent<StudentRegisterDialogComponent> implements OnInit {

  studentPhone: FormControl;
  showNextButton: boolean = false;
  tutoringDurationOptions: Array<{ duration: string, translation: string }>;

  @ViewChild("phoneInput") phoneInput: any;

  async ngOnInit() {
    this.registrationForm = this.formBuilder.group({
      expectedTutoringDuration: ['', [Validators.required]]
    });
    if (!this.userData.googleId) {
      this.addPersonalDataToForm();
    }
    this.phoneNumberUtil = await this.utilityService.addGooglePhoneNumberLib();
    this.setRecaptcha()
    const translation = this.translationService.instant('texts.Misc_field_mobile_phone_prefix');
    this.studentPhone = new FormControl(translation,
      { validators: [Validators.required, this.utilityService.phonePatternValidator(this.phoneNumberUtil)], updateOn: 'blur' });
    this.getTranslations();
    this.checkToAutofill()
  }

  getTranslations() {
    this.translationService.get([
      'texts.Misc_field_mobile_phone_prefix',
      'texts.Modal_register_student_tutoring_duration',
      'texts.Student_registration_tutoring_duration_0_2_month',
      'texts.Student_registration_tutoring_duration_2_5_month',
      'texts.Student_registration_tutoring_duration_5_10_month',
      'texts.Student_registration_tutoring_duration_plus_10_month',
      // 'texts.Student_registration_tutoring_duration_exam'
    ]).subscribe(translations => {
      this.tutoringDurationOptions = [
        {
          duration: "10+",
          translation: translations["texts.Student_registration_tutoring_duration_plus_10_month"]
        },
        {
          duration: "5-10",
          translation: translations["texts.Student_registration_tutoring_duration_5_10_month"]
        },
        {
          duration: "2-5",
          translation: translations["texts.Student_registration_tutoring_duration_2_5_month"]
        },
        {
          duration: "0-2",
          translation: translations["texts.Student_registration_tutoring_duration_0_2_month"]
        },
        //WE NEED THIS WHEN WE WANT TO SELL EXAM PACKAGES
        // {
        //   duration: "0",
        //   translation: translations["texts.Student_registration_tutoring_duration_exam"]
        // }
      ]
    });
  }

  triggerToast(id: string) {
    this.toastService.showMsg('texts.Misc_Successful_Registration', MessageType.info, { title: 'texts.Misc_Successful_Registration_Title', timeout: 'stick' });
    this.utilityService.trackEvent('Student Register', 'Response', 'Success', id);
    this.utilityService.trackEvent('registration_student', 'email', 'success', id);
  }

  createRegistrationRequestData() {
    if (!this.userData.googleId) {
      this.mapToStudentData()
    }
    this.registerUserRequest = {
      user: {
        personalInfo: {
          forename: this.userData.firstName,
          surname: this.userData.lastName,
          emailAddress: this.userData.email,
          phoneNumber: this.studentPhone.value,
        },
        studentDetails: {
          expectedTutoringDuration: this.registrationForm.value.expectedTutoringDuration
        },
        country: this.utilityService.country,
        role: 'Student',
      },
      password: this.userData.password,
      captcha: this.captcha_value,
      googleId: this.userData.googleId,
    } as RegisterUserRequest;
  }

  private mapToStudentData(): void {
    this.userData.email = this.registrationForm.value.personalDataForm.email;
    this.userData.password = this.registrationForm.value.personalDataForm.password;
    this.userData.firstName = this.registrationForm.value.personalDataForm.firstName;
    this.userData.lastName = this.registrationForm.value.personalDataForm.lastName;
  }

  // autofill most of the inputs on ?autofillBAH
  autoFillData() {
    const email = `${Math.round(Math.random() * 1000000)}-bah@mailinator.com`;
    this.registrationForm.controls.personalDataForm.get('email')?.setValue(email);
    this.registrationForm.controls.personalDataForm.get('password')?.setValue(`Test12345`);
    this.registrationForm.controls.personalDataForm.get('firstName')?.setValue("Mark");
    this.registrationForm.controls.personalDataForm.get('lastName')?.setValue("II");
    this.personalDataRegister.validatePassword('Test12345')
  }

  prevStep() {
    if (this.tabs.currentTab() === 3) {
      this.showNextButton = true;
    };
    this.tabs.prevTab()
  }
}