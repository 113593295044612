<div class="personal-data-container" [formGroup]="form" #ngForm="ngForm" (ngSubmit)="form.valid">
    <div class="dialog-title" *ngIf="!isRegistringAsTutor" translate>texts.Student_Registration_Dialog_Title</div>
    <div class="dialog-title" *ngIf="isRegistringAsTutor" translate>texts.Tutor_Registration_Dialog_Title</div>
    <div class="input-title" translate>texts.Misc_field_email</div>
    <div>
        <input class="input-field" formControlName="email" value="" [placeholder]="'texts.Misc_field_email' | translate"
            (keyup)="isEmailDuplicate = false;" (change)="validateEmailInput(); isEmailDuplicate = false;">
        <div class="error-message" [@errorMessage] *ngIf="emailDuplicateState">
            {{'texts.TutorRegistrationSection_email_duplicate_error'
            | translate}}</div>
        <div class="error-message" [@errorMessage] *ngIf="emailFormatInvalid">
            {{'texts.Misc_field_email_message_error' |
            translate}}</div>
        <app-input-required-error *ngIf="showError" [inputControl]="form.controls.email"></app-input-required-error>
    </div>
    <div class="input-title" translate>texts.Misc_field_password</div>
    <div class="password">
        <input class="input-field" matInput [type]="hidePassword ? 'password' : 'text'"
            [placeholder]="'texts.Misc_field_password' | translate" required=""
            (input)="validatePassword($event.target.value)" #passwordInput formControlName="password">
        <button (click)="hidePassword = !hidePassword" class="password-toggle" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword" tabindex="-1">
            <i *ngIf="!hidePassword" class="fas fa-eye-slash"></i>
            <i *ngIf="hidePassword" class="fas fa-eye"></i>
        </button>
    </div>
    <div class="error-message password-error" [@errorMessage]
        *ngIf="!isPasswordValid && !(form.controls.password.touched && form.controls.password.value === '')">
        {{ 'texts.Modal_please_type_valid_password' | translate }}
    </div>

    <app-input-required-error *ngIf="showError" [inputControl]="form.controls.password"></app-input-required-error>
    <div class="password-validation-section" [ngClass]="{'touched': passwordTouched}">
        <div class="password-info info-text" translate>texts.Student_Registration_password_info</div>
        <ul>
            <li class="list-item"><app-svg-icon *ngIf="minLengthMet" name="password-check"></app-svg-icon><app-svg-icon
                    *ngIf="!minLengthMet" name="password-x"></app-svg-icon>
                <div translate>
                    texts.Misc_password_validation_min_characters</div>
            </li>
            <li class="list-item"><app-svg-icon *ngIf="uppercaseMet" name="password-check"></app-svg-icon><app-svg-icon
                    *ngIf="!uppercaseMet" name="password-x"></app-svg-icon>
                <div translate>
                    texts.Misc_password_validation_uppercase</div>
            </li>
            <li class="list-item"><app-svg-icon *ngIf="lowercaseMet" name="password-check"></app-svg-icon><app-svg-icon
                    *ngIf="!lowercaseMet" name="password-x"></app-svg-icon>
                <div translate>
                    texts.Misc_password_validation_lowercase</div>
            </li>
            <li class="list-item"><app-svg-icon *ngIf="numberMet" name="password-check"></app-svg-icon><app-svg-icon
                    *ngIf="!numberMet" name="password-x"></app-svg-icon>
                <div translate>
                    texts.Misc_password_validation_number</div>
            </li>
        </ul>
    </div>
    <div class="name-info">
        <div class="flex-box">
            <div class="input-title" *ngIf="!isRegistringAsTutor" translate>texts.Student_Registration_Student_Firstname
            </div>
            <div class="input-title" *ngIf="isRegistringAsTutor" translate>texts.Tutor_Registration_Student_Firstname
            </div>
            <input class="small-input-field input-field" [placeholder]="'texts.Misc_field_firstname' | translate"
                required="" formControlName="firstName">
            <app-input-required-error *ngIf="showError"
                [inputControl]="form.controls.firstName"></app-input-required-error>
        </div>
        <div class="flex-box">
            <div class="input-title" *ngIf="!isRegistringAsTutor" translate>texts.Student_Registration_Student_Lastname
            </div>
            <div class="input-title" *ngIf="isRegistringAsTutor" translate>texts.Tutor_Registration_Student_Lastname
            </div>
            <input class="small-input-field input-field" [placeholder]="'texts.Misc_field_lastname' | translate"
                required="" formControlName="lastName">
            <app-input-required-error *ngIf="showError"
                [inputControl]="form.controls.lastName"></app-input-required-error>
        </div>
    </div>
    <div class="next-button button" type="submit" (click)="confirm()">
        <div translate>texts.Misc_next_button</div>
    </div>
</div>