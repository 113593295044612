<app-navbar *ngIf="!utilityService.isSemiFullScreen "></app-navbar>
<div class="template-content">
	<div class="template-content__container">
		<router-outlet></router-outlet>
	</div>
</div>
<app-footer-section *ngIf="!utilityService.isSemiFullScreen && utilityService.isFooterVisible"></app-footer-section>
<div class="msg-wrapper" [ngClass]="{'visible': toastService.messages.length}">
	<ng-template #messageInsert></ng-template>
</div>
<div *ngIf="pageLoadAction(pageLoadService.pageTemplate$ | async)"></div>
<app-login-modal *ngIf="popupService.isLoginModal"></app-login-modal>
<app-login-register-modal *ngIf="popupService.isLoginOrRegisterModal"></app-login-register-modal>
<app-register-modal *ngIf="popupService.isRegisterModal"></app-register-modal>