import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';
import { AuthService, PopupService } from 'app/services';
import { RegisterType } from 'app/microservice-clients/user';

@Component({
  selector: 'app-select-register-type',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './select-register-type.component.html',
  styleUrls: ['./select-register-type.component.scss']
})
export class SelectRegisterTypeComponent {

  constructor(
    public authService: AuthService,
    public popupService: PopupService
  ) { }

  @Output() onEmailRegister = new EventEmitter();
  @Output() onCloseDialog = new EventEmitter();

  @Input() isRegistringAsTutor: boolean;

  loginWithGoogle() {
    this.authService.loginWithGoogle();
    if (this.isRegistringAsTutor) {
      localStorage.setItem(RegisterType.isTutorRegister, 'true');
    }
  }

  openLogin() {
    this.popupService.showLoginModal();
    this.onCloseDialog.emit()
  }

  openStudentRegister() {
    this.popupService.showRegisterModalStudent();
    this.onCloseDialog.emit()
  }
}
