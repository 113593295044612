import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
  selector: 'app-range-slider',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent {
  @Input() sliderForm: FormControl;
  @Input() title: string;
  @Input() description: string;
  @Input() minValue: number;
  @Input() maxValue: number;
}
