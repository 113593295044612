import { CommonModule } from '@angular/common';
import { Component, computed, ElementRef, Input, ViewChild, WritableSignal } from '@angular/core';

@Component({
  selector: 'app-custom-stepper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-stepper.component.html',
  styleUrl: './custom-stepper.component.scss'
})
export class CustomStepperComponent {
  @ViewChild('stepBar') stepBar: ElementRef;

  @Input('progressBarHeight') progressBarHeight: number = 2;
  @Input('showPagination') showPagination: boolean = true;
  @Input('totalSteps') totalSteps: WritableSignal<number>;
  @Input('currentStep') currentStep: WritableSignal<number>;

  stepBarProgress = computed(() => this.adjustStepBarProgress());
  prevProgressValue: number = 0;

  constructor() { }

  adjustStepBarProgress() {
    const progress = (this.currentStep() / this.totalSteps()) * 100;
    if (progress > this.prevProgressValue) {
      this.pulse();
    }
    this.prevProgressValue = progress;
    return progress;
  }

  pulse() {
    this.stepBar?.nativeElement.animate(
      [
        {
          boxShadow: '0px 0px 0px 0px rgb(92, 199, 95,.7) , 0px 0px 0px 0px rgb(92, 199, 95,.7)',
          offset: 0,
        },
        {
          boxShadow: '0px 0px 4px 10px rgb(92, 199, 95,.0) , 0px 0px 0px 0px rgb(92, 199, 95,.7)',
          offset: 0.4,
        },
        {
          boxShadow: '0px 0px 4px 10px rgb(92, 199, 95,.0) , 0px 0px 1px 2px rgb(92, 199, 95,.0)',
          offset: 0.8,
        },
        {
          boxShadow: '0px 0px 0px 0px rgb(92, 199, 95,.0) , 0px 0px 1px 2px rgb(92, 199, 95,.0)',
          offset: 1,
        },
      ],
      {
        duration: 1000,
        iterations: 1,
      }
    );
  }
}
