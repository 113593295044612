import { TranslateService } from '@ngx-translate/core';
import { AuthService, PopupService, UtilityService } from '../services';
import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from 'app/standalone-components/common-dialogs/confirm/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})

export class ClassroomService {

    preparedLink: string;

    constructor(
        private authService: AuthService,
        private translationService: TranslateService,
        private utilityService: UtilityService,
        private popupService: PopupService
    ) { }

    async joinClassroom(partnerId: string, recordingLink?: string) {
        this.prepareLink(partnerId);
        if (recordingLink) {
            this.preparedLink = `${this.preparedLink}?recording=${encodeURIComponent(recordingLink)}`;
            this.openLessonSpace();
            return
        } else {
            const recommendationResolved = await this.recommendationBeforeJoiningClassroom();
            if (recommendationResolved) {
                this.openLessonSpace();
            }
        }
    }

    private prepareLink(partnerId: string) {
        const page = this.authService.isTeacher() ? 'ROUTES.Misc_relation' : 'ROUTES.Misc_profile';
        this.translationService.get([
            page,
            'ROUTES.Lessonspace_workarea'
        ]).subscribe(translations => {
            this.preparedLink = `${translations[page]}/${translations['ROUTES.Lessonspace_workarea']}/${partnerId}`;
        });
    }

    private recommendationBeforeJoiningClassroom(): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.utilityService.isDesktopRes || !this.utilityService.isChrome) {
                const dialog = this.popupService.openResponsiveDialog(ConfirmDialogComponent).componentInstance;
                dialog.dialogTitle = 'texts.Recommendation_Before_Joining_Classroom_Title';
                dialog.dialogText = 'texts.Recommendation_Before_Joining_Classroom_Description';
                dialog.dialogConfirmButton = 'texts.Recommendation_Before_Joining_Classroom_Button';
                dialog.onConfirm.subscribe(() => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    private openLessonSpace() {
        window.open(this.preparedLink);
        this.preparedLink = null;
    }
}