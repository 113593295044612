<app-login-or-register-frame [isCloseButtonVisible]="isCloseButtonVisible">
    <app-select-register-type [ngClass]="{'hide-element': hideSelectRegType}" [isRegistringAsTutor]="false"
        (onEmailRegister)="hideSelectRegType = true" (onCloseDialog)="dialogRef.close()"></app-select-register-type>
    <form *ngIf="registrationForm" [ngClass]="{'hide-element': !hideSelectRegType}" [formGroup]="registrationForm"
        #ngForm="ngForm" (ngSubmit)="registrationForm.valid && studentPhone.valid && onSubmit()">
        <div class="back-button" *ngIf="tabs.currentStep > 1" (click)="prevStep()"><app-svg-icon
                name="Alt_Arrow_Left"></app-svg-icon></div>
        <app-custom-tabs #tabs>
            <app-personal-data #personalDataRegister *ngIf="!userData.googleId" (onConfirm)="tabs.nextTab()"
                [form]="registrationForm.get('personalDataForm')"></app-personal-data>
            <div class="stepper-step">
                <div class="section-title" translate>texts.Modal_register_student_tutoring_duration</div>
                <div *ngFor="let option of tutoringDurationOptions">
                    <label class="options-container"
                        [ngClass]="{'selected': option.duration === registrationForm.get('expectedTutoringDuration').value}">
                        <input type="radio" [value]="option.duration" formControlName="expectedTutoringDuration"
                            (click)="tabs.delayedNextTab(500)">
                        <div class="option-text">{{ option.translation }}</div>
                    </label>
                </div>
                <div class="button next-button" (click)="tabs.nextTab();" *ngIf="showNextButton">
                    <div translate>texts.Misc_next_button</div>
                </div>
            </div>
            <div class="stepper-step">
                <app-phone-input [phoneNumber]="studentPhone" (onConfirm)="submitButton.click()"></app-phone-input>
                <button #submitButton type="submit" style="display: none;">
                </button>
                <div class="bottom-text" translate>texts.Misc_register_tac_text<a class="hyperlink"
                        [routerLink]="['/Misc_terms'] | localize" target="_blank">{{ 'texts.Misc_terms_and_conditions' |
                        translate
                        }}</a>
                </div>
                <re-captcha #recaptchaComponent (resolved)="reCaptchaResolved($event)" (error)="reCaptchaError($event)"
                    [siteKey]=siteKey errorMode="handled" size="invisible">
                </re-captcha>
            </div>
        </app-custom-tabs>
    </form>
</app-login-or-register-frame>