import { UtilityService } from './utility.service';
import { Injectable } from '@angular/core';
import { SequenceOptions } from 'app/constants/packages';
import { UserObj, UserStateEnum } from 'app/microservice-clients/user';

export type Cases = {
  testCase1: boolean | undefined;
  testCase2: Case | undefined;
  testCase3: boolean | undefined;
}
type Case = {
  option: number;
  adviced?: string;
}
@Injectable({
  providedIn: 'root'
})

export class AbTestService {

  cases: Cases
  constructor(public utilityService: UtilityService) { }
  setAbTestDataForStudents(user: UserObj) {
    const id = +user.id;
    const createdMilisec = +user.createdAtMillis
    this.cases = {
      testCase1: this.setDataForCase1(user.state, id),
      testCase2: this.setDataForCase2(id),
      testCase3: this.setDataForCase3(createdMilisec)
    }
  }

  setDataForCase1(state: UserStateEnum, id: number): boolean {
    // For users with id ending 0-4, we show package after register part 2 is finished, for users with id ending 5-9, we show package after the first booking is made

    const idLastDigit = id % 10
    if ((idLastDigit >= 0 && idLastDigit <= 4) &&
      (state === UserStateEnum.Active ||
        state === UserStateEnum.Introducing ||
        state === UserStateEnum.Tutoring)) {
      return true
    }

    if ((idLastDigit >= 5 && idLastDigit <= 9) &&
      (state === UserStateEnum.Introducing ||
        state === UserStateEnum.Tutoring)) {
      return true
    }
    return false
  }

  setDataForCase2(id: number): Case | undefined {
    const lastDigit = id % 10;
    // Option 1: ID ends in 0-3: show current 12 hour version and not the 16 hour
    if (lastDigit >= 0 && lastDigit <= 3) {
      return { option: 1, adviced: '2' };
    }

    const lessonOptionUnderTesting = {
      text: 'texts.Pricing_Page_Packages_lessons_per_week_4',
      textMonthly: 'texts.Pricing_Page_Packages_lessons_per_month_16',
      value: '4',
      isActive: false
    };
    SequenceOptions.unshift(lessonOptionUnderTesting);

    // Option 2 ID ends with 4-5: show the 16 hour option, but do not have any ''advised'' badge on any option
    if (lastDigit === 4 || lastDigit === 5) {
      return { option: 2, adviced: '0' };
    }

    // ID ends with 6-7: show the 16 hour option, and make the middle 2 options (8 and 12) bigger than the 4 and 16 hour version, so they stand out more. There is not design for it, but I think it should be OK.
    if (lastDigit === 6 || lastDigit === 7) {
      return { option: 3, adviced: '0' };
    }

    // ID ends with 8-9: show the 16 hour option, and put the advice badge on 12 hours per week.
    if (lastDigit === 8 || lastDigit === 9) {
      return { option: 4, adviced: '3' };

    }
  }

  setDataForCase3(createdMilisec) {
    // For users created in odd seconds we show a 4 month package on package cards, instead of 3 months. For users created in even seconds, we show 3 months, only for DE

    if (createdMilisec % 2 === 0 || this.utilityService.isNL) {
      return true
    }
    return false
  }
}
