<div class="additional-information-container">
    <div class="stepper-container">
        <div class="arrow-back" *ngIf="additionalInformation.tabs?.activeTabIndex() > 0">
            <i class='fas fa-chevron-left' (click)="additionalInformation.tabs.prevTab()"></i>
        </div>
    </div>
    <div class="close-button">
        <i class="fas fa-times" (click)="close()"></i>
    </div>
    <app-additional-information #additionalInformation
        (onProfileCompleted)="profileCompleted()"></app-additional-information>
</div>