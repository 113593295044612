import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyPackageAfterBookingComponent } from 'app/standalone-components/new-packages/buy-package-after-booking/buy-package-after-booking.component';
import { SharedModule } from 'app/modules/shared/shared.module';
import { PackageCardsComponent } from '../package-cards/package-cards.component';
import { UserObj } from 'app/microservice-clients/user';
import { SelectedPackageData } from 'app/models/package.model';
import { BookingDataModel, RecurrentBookingDataModel } from 'app/models';

@Component({
  selector: 'app-lets-start-new-package',
  standalone: true,
  imports: [CommonModule, SharedModule, PackageCardsComponent, BuyPackageAfterBookingComponent],
  templateUrl: './lets-start-new-package.component.html',
  styleUrls: ['./lets-start-new-package.component.scss']
})
export class LetsStartNewPackageComponent {

  @Input() tutorObj: UserObj;
  @Input() showPictureAndSvg: boolean;
  @Input() showTutorLevelOptions: boolean;
  @Input() applyCustomStyling: boolean;
  @Input() selectedPackageData?: SelectedPackageData;
  @Input() bookingDetailsAfterPayment?: BookingDataModel;
  @Input() bookingRecurrentDetailsAfterPayment?: RecurrentBookingDataModel;

  tutorName: string;
  tutorLevel: string;
  selectedLessonPerWeek
  selectedTutorLevel
  currentStep = 1
  maxSteps = 3

  @Output() onClose = new EventEmitter();
  @Output() onNewPackagePurchase = new EventEmitter();
  @ViewChild('packageCardComponent') packageCardComponent: PackageCardsComponent;

  ngOnInit() {
    if (this.selectedPackageData) {
      this.currentStep = 2;
    }
    if (this.tutorObj) {
      this.tutorName = this.tutorObj.personalInfo.forename
      this.tutorLevel = this.tutorObj.tutorDetails.category
    }
  }

  nextStep(lessonPerWeek, tutorLevel?) {
    this.selectedLessonPerWeek = lessonPerWeek
    this.selectedTutorLevel = tutorLevel
    this.currentStep++
  }

  previousStep() {
    if (this.currentStep === 3) {
      this.packageCardComponent.isPaymentMethodOpened = false
    }
    this.currentStep--
  }
}