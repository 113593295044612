<div class="package-status-container">
  <!-- pending -->
  <ng-container *ngIf="
      !packagesFlowService.suspendedPackage &&
      (packagesFlowService.pendingPackage ||
        packagesFlowService.enrollingPackageButCreditEnded)
    ">
    <div class="package-status-detail">
      <app-svg-icon name="spinner-duotone" class="spinner"></app-svg-icon>
      <div class="package-status-info-title" translate>
        texts.Package_Status_Pending_Title
      </div>
    </div>

    <button class="action-button" (click)="
        retryPayment(
          packagesFlowService.pendingPackage ||
            packagesFlowService.enrollingPackageButCreditEnded
        )
      " translate>
      texts.Package_Status_Pay_Button
    </button>
  </ng-container>
  <!-- suspended -->
  <ng-container *ngIf="packagesFlowService.suspendedPackage">
    <div class="package-status-detail">
      <app-svg-icon name="danger_triangle"></app-svg-icon>
      <div>
        <div class="package-status-info-title" translate>
          {{
          packagesFlowService.suspendedPackage.paymentIntent?.status ==
          PaymentIntentStatus.success
          ? "texts.Package_Status_Suspended_By_Admin_Title"
          : "texts.Package_Status_Suspended_Not_Available_Title"
          }}
        </div>
        <p class="package-status-info-description" translate>
          {{
          packagesFlowService.suspendedPackage.paymentIntent?.status ==
          PaymentIntentStatus.success
          ? "texts.Package_Status_Suspended_By_Admin_Description"
          : "texts.Package_Status_Suspended_Not_Available_Description"
          }}
        </p>
      </div>
    </div>
    <button class="action-button" (click)="
        packagesFlowService.suspendedPackage.paymentIntent.status ==
        PaymentIntentStatus.success
          ? navigateToContactPage()
          : retryPayment(packagesFlowService.suspendedPackage)
      " translate>
      {{
      packagesFlowService.suspendedPackage.paymentIntent?.status ==
      PaymentIntentStatus.success
      ? "texts.Contact_Us"
      : "texts.Package_Status_Pay_Button"
      }}
    </button>
  </ng-container>
</div>